.Loading-module__root--2zdeO {
  text-align: center;
  padding: var(--mega) 0 var(--tera);
}
.Loading-module__reloadIcon--1Fmqf {
  margin-bottom: var(--milli);
}
.Loading-module__reload--3X_9K {
  margin-top: var(--milli);
}
.Loading-module__spinner--fmMjq {
  display: inline-block;
  position: relative;
  width: var(--kilo);
  height: var(--kilo);
}
.Loading-module__spinnerElement--_VkMM {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: var(--hecto);
  height: var(--hecto);
  margin: var(--femto);
  border: var(--femto) solid var(--icon-as-illustration);
  border-radius: 50%;
  animation: Loading-module__spinner--fmMjq 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--icon-as-illustration) transparent transparent transparent;
}
.Loading-module__spinnerElement--_VkMM:nth-child(1) {
  animation-delay: -0.45s;
}
.Loading-module__spinnerElement--_VkMM:nth-child(2) {
  animation-delay: -0.3s;
}
.Loading-module__spinnerElement--_VkMM:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes Loading-module__spinner--fmMjq {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
