.KeyValue-module__inlineItem--269Zi {
  color: var(--text-hint);
  display: inline-block;
}
.KeyValue-module__inlineItem--269Zi:before {
  content: '\A0';
}

.KeyValue-module__valueHighlighted--bZ2Qz {
  font-weight: var(--font-weight-highlight);
}

.KeyValue-module__valueWithError--ijGVW {
  color: var(--color-error);
}
