/* stylelint-disable no-descending-specificity */
/* Input Styling */
.Input-module__container--3lN1P {
  width: 100%;
  margin-bottom: var(--femto);
}

.Input-module__inputDescription--2X4ZI {
  color: var(--color-input-text);
  font-size: var(--body-small-size);
  font-weight: var(--font-weight-highlight);
  padding-left: var(--pico);
}

.Input-module__labelAndInput--1Ip0I {
  position: relative;
}

.Input-module__input--2S85t {
  background: var(--color-input-background);
  border-radius: var(--atto);
  border: 2.5px solid transparent;
  box-sizing: border-box;
  color: var(--color-black);
  font-size: var(--body-size);
  line-height: var(--body-line);
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
}

.Input-module__input--2S85t::placeholder {
  color: var(--color-input-text);
}

.Input-module__input--2S85t:focus {
  border-radius: var(--atto);
  border: 2.5px solid var(--color-black);
  border-radius: var(--atto);
  outline: none;
}

.Input-module__inputLabel--Rqg7b {
  background: transparent;
  color: var(--color-input-text);
  font-size: var(--body-small-size);
  font-weight: var(--font-weight-highlight);
  left: 0;
  letter-spacing: var(--letter-spacing-small);
  line-height: var(--micro);
  margin: 0;
  padding-left: var(--pico);
  padding-top: var(--femto);
  position: absolute;
  text-transform: none;
  top: 0;
}

.Input-module__input--2S85t:focus + .Input-module__inputLabel--Rqg7b {
  color: var(--color-black);
}

.Input-module__iconSpan--1YY6R {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  margin: 0;
  position: absolute;
  top: 0;
}

.Input-module__iconSpan--1YY6R svg {
  height: var(--micro);
  padding-bottom: var(--pico);
  width: var(--micro);
}

input.Input-module__disabledInput--3CdNe,
textarea.Input-module__disabledInput--3CdNe,
select.Input-module__disabledInput--3CdNe {
  background: var(--disabled-input-background);
  color: var(--color-input-text);
}

input.Input-module__disabledInput--3CdNe::placeholder,
select.Input-module__disabledInput--3CdNe::placeholder,
textarea.Input-module__disabledInput--3CdNe::placeholder,
label.Input-module__disabledInputLabel--1Ctoz {
  color: var(--line-bottom);
}

span.Input-module__disabledIconSpan--1N-eZ svg path {
  fill: var(--line-bottom);
}

/* Error Input */
.Input-module__error--xeytc {
  color: var(--text-error);
  font-size: var(--body-small-size);
  font-weight: var(--font-weight-highlight);
  padding-left: var(--pico);
  padding-top: var(--atto);
}

.Input-module__errorInput--1E15t:focus {
  border: 2.5px solid var(--color-error);
}

label.Input-module__errorLabel--3UoOD {
  color: var(--color-error);
}

.Input-module__input--2S85t:focus + label.Input-module__errorLabel--3UoOD {
  color: var(--color-error);
}

/* Trailing Icon (icon left) */
.Input-module__trailingIconInput--1T6Ak {
  padding: var(--centi) var(--hecto) var(--femto)
    calc(var(--zepto) + var(--deka));
}

.Input-module__trailingIconLabel--RBF8C {
  padding-left: calc(var(--atto) + var(--deka));
}

.Input-module__input--2S85t:focus + .Input-module__trailingIconLabel--RBF8C {
  color: var(--color-black);
}

.Input-module__trailingIconSpan--1VyBY {
  left: var(--pico);
}

.Input-module__trailingIconError--2YfD9 {
  padding-left: calc(var(--atto) + var(--deka));
}

/* Non trailing icon (icon right) */
.Input-module__nonTrailingIconInput--XNOHj {
  padding: var(--centi) calc(var(--atto) + var(--deka)) var(--femto) 10px;
}

.Input-module__nonTrailingIconInput--XNOHj:focus {
  border-radius: var(--atto);
  border: 2.5px solid var(--color-black);
  outline: none;
}

.Input-module__iconRight--ClAFk {
  right: var(--body-small-size);
}

/* Password Input */
button.Input-module__passwordIcon--1_Sil {
  padding: 0;
  border: 1.5px solid transparent;
  background: transparent;
}

button.Input-module__passwordIcon--1_Sil:hover {
  border: 1.5px solid transparent;
  background: transparent;
}

button.Input-module__passwordIcon--1_Sil:focus {
  box-shadow: none;
  background: transparent;
  border: 1.5px solid var(--color-black);
}

button.Input-module__passwordIcon--1_Sil svg {
  padding-bottom: 0;
}

.Input-module__searchInput--3o60- {
  padding: var(--nano) var(--kilo) var(--nano) var(--pico);
}

.Input-module__inputHasValue--1f39s .Input-module__searchInput--3o60- {
  padding: var(--nano) var(--mega) var(--nano) var(--pico);
}

.Input-module__searchInputContainer--MJ8Ey {
  position: relative;
}

button[type='submit'].Input-module__searchInputButton--20gOB,
button[type='reset'].Input-module__resetInputButton--1xlGr {
  -webkit-appearance: none;
  background: transparent;
  bottom: 0;
  height: var(--deka);
  line-height: 0;
  margin: auto;
  padding: 0;
  position: absolute;
  right: var(--pico);
  top: 0;
  width: var(--deka);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

button[type='reset'].Input-module__resetInputButton--1xlGr {
  display: none;
}

.Input-module__inputHasValue--1f39s button[type='reset'].Input-module__resetInputButton--1xlGr {
  right: var(--kilo);
  border: none;
  display: block;
}

.Input-module__inputHasValue--1f39s button[type='submit'].Input-module__searchInputButton--20gOB {
  background: var(--pure-black);
}

.Input-module__inputHasValue--1f39s [data-path='IconSearch'] {
  fill: var(--pure-white);
}
